import { createBrowserRouter, createHashRouter } from "react-router-dom"

import GuestRoute from "routes/middleware/guest"
import ProtectedRoute from "routes/middleware/protected"

import Dashboard from "pages/dashboard"
import Login from "pages/auth/login"
import Register from "pages/auth/register"
import NoRole from "pages/no-role"
import { show as showDashboard } from "./loader/dashboard"
import { load as loadMainData } from "./loader/main"

import { show as showBankStatement } from "./loader/bank-statement"
import { show as showGatewayStatement } from "./loader/gateway-statement"

import { getByUser as getBranchByUser } from "./loader/branch"
import SelectBranch from "pages/select-branch"
import { lang } from "config"
import Error from "pages/error"
import { List as BankStatementList, Detail as BankStatementDetail } from "pages/bank-statement"
import { List as GatewayStatementList, Detail as GatewayStatementDetail } from "pages/gateway-statement"
import { List as DisbursementList } from "pages/disbursement"

import AppLayout from "layouts/app-layout"
import Match from "pages/match"
import Summary from "pages/summary"

export const routeList = [
    // Root loader
    {
        loader: loadMainData,
        errorElement: <Error />,
        children: [
            // Guest
            {
                path: "/register",
                element: (
                    <GuestRoute>
                        <Register title={lang.title.sign_up} />
                    </GuestRoute>
                )
            },
            {
                path: "/login",
                element: (
                    <GuestRoute>
                        <Login title={lang.title.sign_in} />
                    </GuestRoute>
                )
            },

            // Protected
            {
                path: "/branch/select",
                element: (
                    <ProtectedRoute>
                        <SelectBranch title={lang.title.select_branch} />
                    </ProtectedRoute>
                ),
                loader: getBranchByUser
            },
            {
                path: "/no-role",
                element: (
                    <ProtectedRoute>
                        <NoRole title="No Role Assigned" />
                    </ProtectedRoute>
                )
            },

            // Protected, App Layout
            {
                middleware: "protected",
                element: (
                    <ProtectedRoute>
                        <AppLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "/",
                        index: true,
                        title: "Dashboard",
                        element: (
                            <Dashboard />
                        ),
                        // loader: showDashboard
                    },
                    {
                        path: "/bank-statement",
                        title: "Bank Statement",
                        element: (
                            <BankStatementList key="/bank-statement" />
                        )
                    },
                    {
                        path: "/bank-statement/:batch",
                        element: (
                            <BankStatementDetail />
                        ),
                        loader: showBankStatement
                    },
                    {
                        path: "/gateway-statement",
                        title: "Payment Gateway Statement",
                        element: (
                            <GatewayStatementList key="/gateway-statement" />
                        )
                    },
                    {
                        path: "/gateway-statement/:batch",
                        element: (
                            <GatewayStatementDetail />
                        ),
                        loader: showGatewayStatement
                    },
                    {
                        path: "/match",
                        title: "Match",
                        element: (
                            <Match />
                        )
                    },
                    {
                        path: "/summary",
                        title: "Summary",
                        element: (
                            <Summary />
                        )
                    },
                    {
                        path: "/disbursement",
                        title: "Disbursement",
                        element: (
                            <DisbursementList />
                        )
                    }
                ]
            }
        ]
    }
]

export const routes = process.env.NODE_ENV === 'development' ? createBrowserRouter(routeList) : createHashRouter(routeList)

export default routes