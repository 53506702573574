import { useEffect } from "react"
import { Link, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"
import moment from "moment/moment"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import { Pagination, Transition } from "components"
import { lang } from "config"
import { CloudDownload, Pencil, Plus } from "components/icons/outline"
import toast from "react-hot-toast"
import axios from "axios"
import useDisbursementRepositoriess from "repositories/disbursement"
import Filter from "./filter"

export default function List() {
    // Logged in user ability
    const { user, currentBranch } = useAuth()

    // Transition
    const { title, transition, setTransition } = useOutletContext()

    // React router navigate hook
    const navigate = useNavigate()

    // React router location hook
    const location = useLocation()

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const branch = searchParams.get('branch_id') ?? currentBranch?.id
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    const { data: resourceData, isLoading: isLoadingResourceData, mutate: mutateResourceData } = useDisbursementRepositoriess({
        page,
        search,
        branch_id: branch,
        from,
        to
    })

    useEffect(() => {
        window.history.replaceState({}, document.title)

        if (transition.reverse) {
            setTransition({ type: 'slide', reverse: false })
        }
    }, [])

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoadingResourceData) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [resourceData, isLoadingResourceData])

    const sendData = (type) => {
        const timestamp = new Date().toISOString()

        toast.loading("Loading", {
            id: `toast-${timestamp}`
        })

        axios.post(`${process.env.REACT_APP_RECONCILIATION_API_URL}/disbursement/export`, {
            type,
            branch_id: branch,
            from,
            to,
            phone: user?.phone
        }).then((response) => {
            window.open(response.data.url)
            toast.success(response.data.message, {
                id: `toast-${timestamp}`
            })
        }).catch((error) => {
            toast.error(error.response.data.message, {
                id: `toast-${timestamp}`
            })
        })
    }

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    return (
        <Transition type="fade">
            <div className="pb-4 space-y-6 lg:pb-8">
                <div className="flex items-center justify-between text-xs">
                    <div>
                        <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ branch, from, to }).filter(([_, v]) => v != null))} />
                    </div>
                    <div className="flex items-center space-x-2">
                        <button onClick={() => sendData('xlsx')} className={`bg-neutral-800 inline-flex items-center px-4 py-3 space-x-2 text-white transition rounded-xl active:hover:scale-90`}>
                            <CloudDownload className="w-4 h-4" strokeWidth={1.5} />
                            <span>Export XLSX</span>
                        </button>
                    </div>
                </div>
                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                        <thead className="bg-neutral-50 rounded-t-3xl">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Batch</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Transaction Code</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Order ID</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Customer Name</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Merchant Name</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Total</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Transaction Date</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-neutral-200">
                            {/* When loading */}
                            {isLoadingResourceData && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.loading_data}
                                    </td>
                                </tr>
                            )}

                            {/* When there are no list available */}
                            {resourceData?.data.length === 0 && !search && !isLoadingResourceData && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.no_data}
                                    </td>
                                </tr>
                            )}

                            {/* When there are no list available on searching */}
                            {resourceData?.data.length === 0 && search && !isLoadingResourceData && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.no_result}
                                    </td>
                                </tr>
                            )}

                            {resourceData?.data.length > 0 && resourceData.data.map((row) => (
                                <tr key={row.id}>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.batch}
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.transaction_code}
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.order_id}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.customer_name}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.merchant_name}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.total ? Intl.NumberFormat('id-Id', { style: 'currency', currency: 'idr' }).format(row.total) : '-'}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {moment(row.transaction_date).format('MMMM D, YYYY')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination links={resourceData?.links} from={resourceData?.from} to={resourceData?.to} total={resourceData?.total} />
            </div>
        </Transition>
    )
}