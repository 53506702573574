import { Eye, EyeOff, Note } from "components/icons/outline"
import Tooltip from "components/tooltip"
import { useState } from "react"


export default function InformationTooltip({ data, position = "left" }) {
    const [reference, setReference] = useState()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <button disabled={!data} ref={setReference} onClick={() => setIsOpen(!isOpen)} className={`${data ? 'bg-neutral-600' : 'bg-neutral-400'} flex justify-center w-full py-1 text-white transition rounded-full active:hover:scale-90`}>
                {isOpen ? (
                    <Eye className="w-4 h-4" stroke={1.5} />
                ) : (
                    <EyeOff className="w-4 h-4" stroke={1.5} />
                )}
            </button>
            <Tooltip onClose={() => setIsOpen(false)} position={position} isOpen={isOpen} reference={reference}>
                <span className="flex max-w-md py-2 pl-2 pr-4 mr-2 space-x-2 text-xs rounded-2xl bg-neutral-100">
                    <Note className="w-4 h-4" stroke={1.5} />
                    <div className="flex flex-wrap w-full whitespace-normal">
                        {data}
                    </div>
                </span>
            </Tooltip>
        </>
    )
}