import { useEffect, useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nProgress from 'nprogress'
import toast from 'react-hot-toast'
import { checkDue } from 'helpers/date-helper'
import { lang } from 'config'

const Content = ({ data, onClose }) => {
    return (
        <div className="mt-8 space-y-8">
            Statement settled successfully, the transfer code is <span className="inline-flex px-3 py-1 text-sm font-medium border border-blue-200 rounded-full bg-blue-50">{data}</span>
            <div className="text-xs">
                <button onClick={onClose} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Close</span>
                </button>
            </div>
        </div>
    )
}

const TransferCode = ({ isModalOpen, data, onClose }) => {
    const [isOpen, setIsOpen] = useState(isModalOpen ?? false)

    useEffect(() => {
        setIsOpen(isModalOpen)
    }, [isModalOpen])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} title="Transfer Code" content={<Content data={data} onClose={onClose} />} />
        </>
    )
}

export default TransferCode