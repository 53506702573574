import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nProgress from 'nprogress'
import toast from 'react-hot-toast'
import { checkDue } from 'helpers/date-helper'
import { lang } from 'config'
import { RosetteDiscountCheck } from 'components/icons/outline'

const Content = ({ data, success }) => {
    const handleSubmit = (e) => {
        e.preventDefault()
        nProgress.start()

        axios.post(`${process.env.REACT_APP_RECONCILIATION_API_URL}/payment-gateway-statement/${data?.batch}/settle`)
            .then((response) => {
                success(response.data.data.transfer_code)
                toast.success(response.data.message)
            }).catch((error) => {
                toast.error(error.response.data.message)
                console.log(error)
                nProgress.done()
            })
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            Are You sure want to settle <codeblock className="inline-flex px-3 py-1 text-sm font-medium border border-blue-200 rounded-full bg-blue-50">{data?.batch}</codeblock> ?
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Settle</span>
                </button>
            </div>
        </form>
    )
}

const Settle = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSuccess = (value) => {
        closeModal()
        onSuccess(value)
    }

    return (
        <>
            <button disabled={false} onClick={openModal} class={`${false ? 'opacity-50 bg-neutral-900' : 'bg-neutral-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                <RosetteDiscountCheck className="w-6 h-6" />
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title="Settle Data" content={<Content data={data} success={handleSuccess} />} />
        </>
    )
}

export default Settle