import { useEffect, useRef, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import { lang } from "config"
import { useAuth } from "contexts/auth-context"
import { Puzzle, X } from "components/icons/outline"
import moment from "moment"
import axios from "axios"
import toast from "react-hot-toast"
import ErrorMessage from "components/forms/error-message"
import SelectGatewayStatement from "./match/select/gateway"
import SelectBankStatement from "./match/select/bank"
import SelectBatch from "./match/batch"
import InformationTooltip from "./match/information-tooltip"

export default function Match() {
    // Hooks
    const navigate = useNavigate()
    const { user } = useAuth()

    // Data
    const [gatewayStatementData, setGatewayStatementData] = useState([])
    const [bankStatementData, setBankStatementData] = useState([])
    const [resultData, setResultData] = useState([])

    const [gatewayStatementBatch, setGatewayStatementBatch] = useState()
    const [bankStatementBatch, setBankStatementBatch] = useState()

    // Subtotal
    const [gatewayStatementSubtotal, setGatewayStatementSubtotal] = useState(0)
    const [bankStatementSubtotal, setBankStatementSubtotal] = useState(0)

    // Filtered Data
    const [filteredGatewayStatementData, setFilteredGatewayStatementData] = useState([])
    const [filteredBankStatementData, setFilteredBankStatementData] = useState([])
    const [filteredResultData, setFilteredResultData] = useState([])

    // Data Search Query
    const [searchGatewayStatement, setSearchGatewayStatement] = useState()
    const [searchBankStatement, setSearchBankStatement] = useState()
    const [searchResult, setSearchResult] = useState()

    // Error Validation Messages
    const [errors, setErrors] = useState({})

    // Watchers
    useEffect(() => {
        if (searchGatewayStatement) {
            setFilteredGatewayStatementData(gatewayStatementData.filter((row) => row.order_id.toLowerCase().includes(searchGatewayStatement.toLowerCase()) || row.number.toLowerCase().includes(searchGatewayStatement.toLowerCase())))
        } else {
            setFilteredGatewayStatementData(gatewayStatementData)
        }
    }, [searchGatewayStatement])

    useEffect(() => {
        setFilteredGatewayStatementData(gatewayStatementData)
        setSearchGatewayStatement()

        var total = 0

        gatewayStatementData.forEach((row) => {
            if (row.transaction_type === 'cr') {
                total += parseFloat(row.total)
            } else if (row.transaction_type === 'db') {
                total -= parseFloat(row.total)
            }
        })

        setGatewayStatementSubtotal(total)
    }, [gatewayStatementData])

    useEffect(() => {
        if (searchBankStatement) {
            setFilteredBankStatementData(bankStatementData.filter((row) => row.information.toLowerCase().includes(searchBankStatement.toLowerCase()) || row.number.toLowerCase().includes(searchBankStatement.toLowerCase())))
        } else {
            setFilteredBankStatementData(bankStatementData)
        }
    }, [searchBankStatement])

    useEffect(() => {
        setFilteredBankStatementData(bankStatementData)
        setSearchBankStatement()

        var total = 0

        bankStatementData.forEach((row) => {
            if (row.transaction_type === 'cr') {
                total += parseFloat(row.amount)
            } else if (row.transaction_type === 'db') {
                total -= parseFloat(row.amount)
            }
        })

        setBankStatementSubtotal(total)
    }, [bankStatementData])

    useEffect(() => {
        setFilteredResultData(resultData)
        setSearchResult()
    }, [resultData])

    // Match handler
    const match = () => {
        axios.post(`${process.env.REACT_APP_RECONCILIATION_API_URL}/match/validate`, {
            bank: {
                subtotal: bankStatementSubtotal,
                data: bankStatementData
            },
            gateway: {
                subtotal: gatewayStatementSubtotal,
                data: gatewayStatementData
            }
        }).then((response) => {
            var matchedResult = resultData

            const result = {
                bank: bankStatementData,
                gateway: gatewayStatementData
            }

            matchedResult.push(result)

            setGatewayStatementData([])
            setBankStatementData([])
            setResultData(matchedResult)

            toast.success(response.data.message)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                }
            })

            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        axios.post(`${process.env.REACT_APP_RECONCILIATION_API_URL}/match`, {
            data: resultData.filter((row) => !row.is_saved),
            created_by: user?.name
        }).then((response) => {
            navigate('/summary')

            toast.success(response.data.message)
        }).catch((error) => {
            toast.error(error.response.data.message !== '' ? error.response.data.message : error.response.status)

            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
        })
    }

    // Data removal handlers
    const removeReference = (data) => {
        setGatewayStatementData(gatewayStatementData.filter((row) => row.order_id !== data.order_id))
    }

    const removeEStatement = (data) => {
        setBankStatementData(bankStatementData.filter((row) => row.number !== data.number))
    }

    // Select batch handler
    const handleSelectBatch = (data) => {
        setBankStatementBatch(data.bank)
        setGatewayStatementBatch(data.gateway)
    }

    return (
        <div className="space-y-8">
            <div className="space-y-6">
                <div className="flex items-center justify-between text-xs">
                    <SelectBatch data={{ bank: bankStatementBatch, gateway: gatewayStatementBatch }} onSelect={(value) => handleSelectBatch(value)} error={false} />
                    <div>
                        <button onClick={() => match()} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                            <Puzzle className="w-4 h-4" />
                            <span>Match</span>
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className={`${errors['gateway.data'] || errors['gateway.subtotal'] ? 'border-red-200' : 'border-neutral-200'} p-4 pt-2 border rounded-xl h-max`}>
                        <span className="text-xs text-neutral-500">Payment Gateway Statement</span>
                        <div className="space-y-6">
                            <span className="font-medium text-neutral-700">{gatewayStatementBatch}</span>
                            <div className="flex items-center justify-between text-xs">
                                <div className="flex items-center space-x-2">
                                    <div className="relative">
                                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <circle cx={10} cy={10} r={7}></circle>
                                                <line x1={21} y1={21} x2={15} y2={15}></line>
                                            </svg>
                                        </div>
                                        <input onChange={(e) => setSearchGatewayStatement(e.target.value)} value={searchGatewayStatement} type="text" placeholder={`${lang.search} PG Statement`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                    </div>
                                    {/* <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ transactionNumber, transactionType, currency, amount, from, to }).filter(([_, v]) => v != null))} /> */}
                                </div>
                                <SelectGatewayStatement batch={gatewayStatementBatch} selected={gatewayStatementData} onSelect={(value) => setGatewayStatementData(value)} error={false} />
                            </div>
                            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                    <thead className="bg-neutral-50 rounded-t-3xl">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.number}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Order ID</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Transaction Code</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Source</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Customer Name</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200">
                                        {/* When there are no list available */}
                                        {filteredGatewayStatementData.length === 0 && !searchGatewayStatement && (
                                            <tr className="text-center">
                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {lang.no_data}
                                                </td>
                                            </tr>
                                        )}

                                        {/* When there are no list available on searching */}
                                        {filteredGatewayStatementData.length === 0 && searchGatewayStatement && (
                                            <tr className="text-center">
                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {lang.no_result}
                                                </td>
                                            </tr>
                                        )}

                                        {filteredGatewayStatementData.length > 0 && filteredGatewayStatementData.map(row => (
                                            <tr>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.number}
                                                </td>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.order_id}
                                                </td>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.transaction_code}
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                                    {row.source}
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                                    {row.customer_name}
                                                </td>
                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {Intl.NumberFormat('id-Id', { style: 'currency', currency: "idr" }).format(row.total)}
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {moment(row.transaction_date).format('MMMM D, YYYY')}
                                                </td>
                                                <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                    <button onClick={() => removeReference(row)} class="bg-red-800 inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90">
                                                        <X className="w-6 h-6" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <span className="text-xs text-neutral-500">{`Total Amount: ${Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(gatewayStatementSubtotal)}`}</span>
                        </div>
                        <ErrorMessage error={errors['gateway.data'] ?? errors['gateway.subtotal']} />
                    </div>

                    <div className={`${errors['bank.data'] || errors['bank.subtotal'] ? 'border-red-200' : 'border-neutral-200'} p-4 pt-2 border rounded-xl h-max`}>
                        <span className="text-xs text-neutral-500">Bank Statement</span>
                        <div className="space-y-6">
                            <span className="font-medium text-neutral-700">{bankStatementBatch}</span>
                            <div className="flex items-center justify-between text-xs">
                                <div className="flex items-center space-x-2">
                                    <div className="relative">
                                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <circle cx={10} cy={10} r={7}></circle>
                                                <line x1={21} y1={21} x2={15} y2={15}></line>
                                            </svg>
                                        </div>
                                        <input onChange={(e) => setSearchBankStatement(e.target.value)} value={searchBankStatement} type="text" placeholder={`${lang.search} Bank Statement`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                    </div>
                                </div>
                                <SelectBankStatement batch={bankStatementBatch} selected={bankStatementData} onSelect={(value) => setBankStatementData(value)} error={errors.bank} />
                            </div>
                            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                    <thead className="bg-neutral-50 rounded-t-3xl">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.number}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.description}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.date}</th>
                                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200">
                                        {/* When there are no list available */}
                                        {filteredBankStatementData.length === 0 && !searchBankStatement && (
                                            <tr className="text-center">
                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {lang.no_data}
                                                </td>
                                            </tr>
                                        )}

                                        {/* When there are no list available on searching */}
                                        {filteredBankStatementData.length === 0 && searchBankStatement && (
                                            <tr className="text-center">
                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {lang.no_result}
                                                </td>
                                            </tr>
                                        )}
                                        {filteredBankStatementData.length > 0 && filteredBankStatementData.map((row) => (
                                            <tr>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.number}
                                                </td>
                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    <InformationTooltip data={row.information} position="left" />
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {row.transaction_type === 'cr' ? lang.credit : lang.debit}
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {`${row.bank_statement.currency} ${Intl.NumberFormat('id-Id').format(row.amount)}`}
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {moment(row.transaction_date).format('MMMM D, YYYY')}
                                                </td>
                                                <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                    <button onClick={() => removeEStatement(row)} class="bg-red-800 inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90">
                                                        <X className="w-6 h-6" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <span className="text-xs text-neutral-500">{`Total Amount: ${Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(bankStatementSubtotal)}`}</span>
                        </div>
                        <ErrorMessage error={errors['bank.data'] ?? errors['bank.subtotal']} />
                    </div>
                </div>

                <div className={`${errors.data ? 'border-red-200' : 'border-neutral-200'} p-4 pt-2 space-y-6 border rounded-xl h-max`}>
                    <span className="text-xs text-neutral-500">{lang.result}</span>
                    <div className="flex items-center justify-between text-xs">
                        <div className="flex items-center space-x-2">
                            <div className="relative">
                                <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <circle cx={10} cy={10} r={7}></circle>
                                        <line x1={21} y1={21} x2={15} y2={15}></line>
                                    </svg>
                                </div>
                                <input onChange={(e) => setSearchResult(e.target.value)} value={searchResult} type="text" placeholder={`${lang.search} ${lang.result}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                            </div>
                            {/* <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ transactionNumber, transactionType, currency, amount, from, to }).filter(([_, v]) => v != null))} /> */}
                        </div>
                    </div>
                    <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                        <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                            <thead className="bg-neutral-50 rounded-t-3xl">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Payment Gateway</th>
                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Bank</th>
                                    <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-neutral-200">
                                {/* When there are no list available */}
                                {filteredResultData.length === 0 && !searchResult && (
                                    <tr className="text-center">
                                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                            {lang.no_data}
                                        </td>
                                    </tr>
                                )}

                                {/* When there are no list available on searching */}
                                {filteredResultData.length === 0 && searchResult && (
                                    <tr className="text-center">
                                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                            {lang.no_result}
                                        </td>
                                    </tr>
                                )}
                                {filteredResultData.length > 0 && filteredResultData.map((row) => (
                                    <tr>
                                        <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                            {row.gateway.map((data, index) => <>{`${data.number} ${index === (row.gateway.length - 1) ? '' : ', '}`}</>)}
                                        </td>
                                        <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                            {row.bank.map((data, index) => (<>{`${data.number} ${index === (row.bank.length - 1) ? '' : ', '}`}</>))}
                                        </td>
                                        <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                            {/* <button class="bg-red-800 inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90">
                                                        <X className="w-6 h-6" />
                                                    </button> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <ErrorMessage error={errors.data} />
                    <div className="flex justify-end text-xs">
                        <button type="button" onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                            <span>{lang.submit}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}