import { Disclosure } from "@headlessui/react"
import { lang } from "config"
import { useAuth } from "contexts/auth-context"
import { AnimatePresence, motion, useAnimationControls } from "framer-motion"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Tailwind
import resolveConfig from 'tailwindcss/resolveConfig'
import defaultTheme from 'tailwindcss/defaultTheme'
import { usePage } from "contexts/page-context"
import { AB, Box, Percentage, Reward, Trademark, ChevronRight, PackageExclusive, ReportMoney, FileInvoice, Puzzle } from "components/icons/outline"

const Sidebar = ({ isSidebarOpen }) => {
    const url = useLocation().pathname
    const { can } = useAuth()
    const { configuration } = usePage()

    const fullConfig = resolveConfig(defaultTheme)

    const sidebar = useAnimationControls()

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    // Listen to the window resize event
    useEffect(() => {
        // window.onresize = () => {
        //     setWindowSize({
        //         width: window.innerWidth,
        //         height: window.innerHeight,
        //     })
        // }

        const updateWindowSizeState = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', updateWindowSizeState)

        return () => window.removeEventListener('resize', updateWindowSizeState)
    }, [])

    useEffect(() => {
        if (windowSize.width < parseInt(fullConfig.theme.screens.lg.replace('px', ''))) {
            if (isSidebarOpen) {
                sidebar.start({
                    x: 0,
                    zIndex: 'auto'
                })
            } else {
                sidebar.start({
                    x: '-4rem',
                    zIndex: '-1'
                })
            }
        } else {
            sidebar.set({
                x: 0,
                zIndex: 'auto'
            })
        }
    }, [isSidebarOpen, windowSize])

    return (
        <motion.div animate={sidebar} transition={{ type: 'spring', damping: 20 }} className="flex flex-col w-0 max-h-screen pt-12 space-y-16 font-sans lg:w-fit">
            <div className="ml-12 text-3xl">
                {process.env.REACT_APP_NAME}
            </div>
            <nav className="w-56 max-h-full px-4 pb-8 ml-8 space-y-8 overflow-x-visible overflow-y-auto text-sm">
                <div className="space-y-2">
                    <div className="text-xs">
                        {lang.pages}
                    </div>
                    <ul>
                        <li>
                            <Link to="/" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                <div className={`${url === '/' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 8.71l-5.333 -4.148a2.666 2.666 0 0 0 -3.274 0l-5.334 4.148a2.665 2.665 0 0 0 -1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-7.2c0 -.823 -.38 -1.6 -1.03 -2.105"></path>
                                        <path d="M16 15c-2.21 1.333 -5.792 1.333 -8 0"></path>
                                    </svg>
                                </div>
                                <div>
                                    {lang.title.dashboard}
                                </div>
                                {url === '/' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                            </Link>
                        </li>
                    </ul>
                </div>

                {(can('bank-statement.view') || can('gateway-statement.view') || can('match.view')) && (
                    <div className="space-y-2">
                        <div className="text-xs">
                            Data
                        </div>
                        <ul className="space-y-6">
                            {(can('bank-statement.view') || can('gateway-statement.view')) && (
                                <li>
                                    <Disclosure>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex items-center justify-between w-full">
                                                    <div className="flex items-center space-x-4">
                                                        <div className={`${(open || url === '/bank-statement' || url === '/gateway-statement') ? 'bg-neutral-900 ring-1 ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                            <FileInvoice className="w-6 h-6" />
                                                        </div>
                                                        <div>
                                                            Statement
                                                        </div>
                                                    </div>
                                                    <div className={`${open ? 'rotate-90' : ''} transition-transform p-[2px] border rounded-full border-neutral-300 bg-neutral-200/50`}>
                                                        <ChevronRight className="w-4 h-4" stroke={1} />
                                                    </div>
                                                </Disclosure.Button>
                                                <AnimatePresence mode="wait">
                                                    {open && (
                                                        <motion.div initial={{ height: 0, marginTop: 0, opacity: 0 }} animate={{ height: 'auto', marginTop: '1rem', opacity: 1 }} exit={{ height: 0, marginTop: 0, opacity: 0 }} transition={{ type: "spring", damping: 20 }} className="overflow-hidden">
                                                            <Disclosure.Panel static>
                                                                <ul className="space-y-4">
                                                                    {can('bank-statement.view') && (
                                                                        <li>
                                                                            <Link to="/bank-statement" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/bank-statement' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/bank-statement' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Bank</span>
                                                                                {url === '/bank-statement' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {can('gateway-statement.view') && (
                                                                        <li>
                                                                            <Link to="/gateway-statement" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/gateway-statement' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/gateway-statement' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Payment Gateway</span>
                                                                                {url === '/gateway-statement' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </Disclosure.Panel>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </>
                                        )}
                                    </Disclosure>
                                </li>
                            )}

                            {can('match.view') && (
                                <li>
                                    <Link to="/match" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/match' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <Puzzle className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Match
                                        </div>
                                        {url === '/match' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}

                {(can('summary.view') || can('disbursement.view')) && (
                    <div className="space-y-2">
                        <div className="text-xs">
                            Report
                        </div>
                        <ul className="space-y-6">
                            {can('summary.view') && (
                                <li>
                                    <Link to="/summary" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/summary' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <ReportMoney className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Summary
                                        </div>
                                        {url === '/summary' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('disbursement.view') && (
                                <li>
                                    <Link to="/disbursement" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/disbursement' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <ReportMoney className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Disbursement
                                        </div>
                                        {url === '/disbursement' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </nav>
        </motion.div>
    )
}

export default Sidebar