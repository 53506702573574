import { useState } from 'react'
import { Modal } from 'components'
import { useEffect } from 'react'
import moment from 'moment/moment'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import NoRedirectPagination from 'components/no-redirect-pagination'
import { useRef } from 'react'
import InputDate from 'components/forms/input-date'
import { lang } from "config"
import { Plus } from 'components/icons/outline'
import usePaymentGatewayStatementDetailRepositories from 'repositories/payment-gateway-statement-detail'

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const Content = ({ batch, selected, onSelect }) => {
    const [selectedReference, setSelectedReference] = useState(selected)

    const [page, setPage] = useState(1)

    const [search, setSearch] = useState(undefined)
    const [from, setFrom] = useState(undefined)
    const [to, setTo] = useState(undefined)

    const { data: dataListSelection, isLoading: isLoadingDataList, mutate: mutateDataList } = usePaymentGatewayStatementDetailRepositories({
        page,
        status: 'outstanding',
        batch,
        paginate: 8,
        search,
        from,
        to
    })

    // const prevSelectedReference = usePrevious(selectedReference)

    useEffect(() => {
        mutateDataList()

        // if (prevSelectedReference !== selectedReference) {
        //     setPage(1)
        // }
    }, [selectedReference, page])

    useEffect(() => {
        setPage(1)
    }, [from, to, search])

    const handleChange = (value) => {
        if (selectedReference.find(row => row.number === value.number)) {
            setSelectedReference(selectedReference.filter((row) => row.number !== value.number))
        } else {
            setSelectedReference([...selectedReference, value])
        }
    }

    const handleSelect = () => {
        onSelect(selectedReference)
    }

    const clearSelection = () => {
        setSelectedReference([])
    }

    const resetFilter = (e) => {
        e.preventDefault()

        // if (Object.keys(selectedReference).length === 0) {
        setSearch('')
        // }

        setFrom(undefined)
        setTo(undefined)
    }

    return (
        <div className="mt-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:grid-cols-9">
                <div className="col-span-2">
                    <Label htmlFor="search" value={lang.search} />
                    <Input onChange={e => setSearch(e.target.value)} value={search} id="search" />
                </div>

                <div className="col-span-2">
                    <Label htmlFor="from" value={lang.from} />
                    <InputDate id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-M-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                </div>

                <div className="col-span-2">
                    <Label htmlFor="to" value={lang.to} />
                    <InputDate id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-M-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                </div>

                {search && (selectedReference.length === 0 || from || to) && (
                    <div className="flex items-center text-xs text-neutral-700">
                        <button onClick={resetFilter} className="mt-5">{lang.reset}</button>
                    </div>
                )}
            </div>

            <div className="mt-4 overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.number}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Order ID</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Transaction Code</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Outlet Group</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Outlet</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Source</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Customer Name</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {/* When loading */}
                        {isLoadingDataList && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.loading_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available */}
                        {!isLoadingDataList && dataListSelection?.data.length === 0 && !search && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available on searching */}
                        {!isLoadingDataList && dataListSelection?.data.length === 0 && search && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_result}
                                </td>
                            </tr>
                        )}

                        {!isLoadingDataList && dataListSelection?.data.length !== 0 && dataListSelection?.data.map(row => (
                            <tr onClick={() => handleChange(row)} className="transition cursor-pointer select-none hover:bg-neutral-50" key={row.number}>
                                <td className="pl-6">
                                    <div className="flex items-center h-5">
                                        <input onChange={() => handleChange(row)} checked={selectedReference.length !== 0 && selectedReference.find((selected) => selected.number == row.number)} id="selected" name="selected" type="checkbox" className="w-4 h-4 rounded-full border-neutral-300 text-neutral-800" />
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.number}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.order_id}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.transaction_code}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                    {row.outlet_group_name}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                    {row.outlet_name}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                    {row.source}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                    {row.customer_name}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {Intl.NumberFormat('id-Id', { style: 'currency', currency: "idr" }).format(row.total)}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {moment(row.transaction_date).format('MMMM D, YYYY')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex items-start mt-8 space-x-2 text-xs">
                <button type="button" onClick={handleSelect} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.select}</span>
                </button>
                {selectedReference.length !== 0 && (
                    <button type="button" onClick={clearSelection} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                        <span>{lang.clear}</span>
                    </button>
                )}
                <NoRedirectPagination current={dataListSelection?.current_page} links={dataListSelection?.links} from={dataListSelection?.from} to={dataListSelection?.to} total={dataListSelection?.total} update={(value) => setPage(value)} />
            </div>
        </div>
    )
}

const SelectGatewayStatement = ({ batch, selected, onSelect, error }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSelect = (value) => {
        onSelect(value)
        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className={`${error ? 'border-red-200' : 'border-neutral-200'} inline-flex items-center px-4 py-3 space-x-2 transition border bg-neutral-50 rounded-xl active:hover:scale-90`}>
                <Plus className="w-4 h-4" />
            </button>
            <Modal size="6xl" isOpen={isOpen} onClose={closeModal} title="Select Payment Gateway Statement" content={<Content batch={batch} selected={selected} onSelect={handleSelect} />} />
        </>
    )
}

export default SelectGatewayStatement