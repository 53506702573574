import { useState } from 'react'
import { Modal } from 'components'
import { useEffect } from 'react'
import moment from 'moment/moment'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import NoRedirectPagination from 'components/no-redirect-pagination'
import { useRef } from 'react'
import InputDate from 'components/forms/input-date'
import { lang } from "config"
import { FileInvoice, Plus } from 'components/icons/outline'
import useBankStatementRepositories from 'repositories/bank-statement'
import SelectDescription from 'components/forms/select-description'
import ErrorMessage from 'components/forms/error-message'
import usePaymentGatewayStatementRepositories from 'repositories/payment-gateway-statement'

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const Content = ({ data, selected, onSelect }) => {
    const [paymentGatewayStatement, setPaymentGatewayStatement] = useState(data?.gateway)
    const [bankStatement, setBankStatement] = useState(data?.bank)

    const { data: bankStatementSelection, isLoading: isLoadingBankStatementSelection, mutate: mutateBankStatement } = useBankStatementRepositories({
        paginate: 'false'
    })

    const { data: paymentGatewayStatementSelection, isLoading: isLoadingPaymentGatewayStatementSelection, mutate: mutatePaymentGatewayStatement } = usePaymentGatewayStatementRepositories({
        paginate: 'false'
    })

    const [errors, setErrors] = useState({})

    const handleSubmit = () => {
        onSelect({
            bank: bankStatement,
            gateway: paymentGatewayStatement
        })
    }

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-4">
                <div className="grid gap-4">
                    <div>
                        <Label htmlFor="bank_statement" value="Bank Statement" />
                        <SelectDescription onChange={(value) => setBankStatement(value)} selection={bankStatementSelection} isLoading={isLoadingBankStatementSelection} value={bankStatement} keyValue={(value) => value.batch} title={(value) => value.batch} description={(value) => moment(value.created_at).format('MMMM D, YYYY')} placeholder="Select Bank Statement" error={errors.bank_statement} />
                        <ErrorMessage error={errors.bank_statement} />
                    </div>
                    <div>
                        <Label htmlFor="payment_gateway_statement" value="Payment Gateway Statement" />
                        <SelectDescription onChange={(value) => setPaymentGatewayStatement(value)} selection={paymentGatewayStatementSelection} isLoading={isLoadingPaymentGatewayStatementSelection} value={paymentGatewayStatement} keyValue={(value) => value.batch} title={(value) => value.batch} description={(value) => moment(value.created_at).format('MMMM D, YYYY')} placeholder="Select Payment Gateway Statement" error={errors.payment_gateway_statement} />
                        <ErrorMessage error={errors.payment_gateway_statement} />
                    </div>
                </div>
            </div>
            <div className="text-xs">
                <button onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.save}</span>
                </button>
            </div>
        </div>
    )
}

const SelectBatch = ({ data, onSelect, error }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSelect = (value) => {
        onSelect(value)
        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className={`${error ? 'border-red-200' : 'border-neutral-200'} inline-flex items-center px-4 py-3 space-x-2 transition border bg-neutral-50 rounded-xl active:hover:scale-90`}>
                <span>Select Batch</span>
                <span><FileInvoice className="w-4 h-4" /></span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.select} ${lang.e_statement}`} content={<Content data={data} onSelect={handleSelect} />} />
        </>
    )
}

export default SelectBatch